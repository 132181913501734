import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import ReservationInformation from "./ReservationInformation";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import swal from 'sweetalert';
import { PatchRequest } from "../api/requests/patchRequest";

export default function AwatingReservationDetails({code, reservation, start, end, hasBeenCanceled, setHasBeenCanceled}) {
  const { t } = useTranslation();

  const handleCancelation = () => {
    swal({
      title: t("Cancelation.Title"),
      text: t("Cancelation.Text"),
      buttons: [t("Cancelation.Cancel"), t("Cancelation.Confirm")]
    })
    .then((willDelete) => {
      if (willDelete) {
        try {
          PatchRequest(`/api/v1/reservations/${reservation.id}/cancel`);
        } catch {
          // NAVIGIRAJ NEGDI
        }
        //setHasBeenCanceled(() => true)
        swal(t("Cancelation.Success")).then(() => setHasBeenCanceled(() => true));
      }
    });
  }

  return (
    <>
    {reservation != null ? 
        <div className="flex-center">
        <div className="details-container">
          
        <ReservationInformation 
              start={start} 
              startTime={reservation.startTime} 
              endTime={reservation.endTime}
              numberOfPersons={reservation.numberOfPersons} />
          
          <div className="flex-center">
            <QRCode
              size={256}
              fgColor="#173655"
              style={{ height: "auto", maxWidth: "50%", width: "50%", margin: '1em 0'}}
              value={code}
              viewBox={`0 0 256 256`}
            />
          </div>
          
          <div className="flex-center">
            <p className="standard-p">
              {t("ReservationDetails.ThankYou", {
                locationName: reservation.locationName,
              })}
              <br />
              <br />
              {t("ReservationDetails.ShareCode")}
            </p>
          </div>
          
          <div className="flex-center">
            <div className="flex-center">
              <WhatsappShareButton
                url={
                  "https://book.seatlio.com/r/" + code
                }
                quote={t("ReservationDetails.WhatsappQuote", {
                  locationName: reservation.locationName,
                })}
              >
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
          {reservation.status === 30 || hasBeenCanceled || !reservation.isCancellable ? 
              
              <p className="standard-p">
                {t("Cancelation.NotAccessible")}
              </p>
              :  
              <>
                <p className="standard-p">
                  {t("Cancelation.Proceed")}
                </p>

                <button className="next-button" onClick={handleCancelation} >
                  {t("Cancelation.Submit")}
                </button>
              </> 
            }
        </div>
      </div>
      : null}
    </>
  );
}