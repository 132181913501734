import { useTranslation } from "react-i18next";

export default function NotConfirmedReservationDetails({reservation}) {
  const { t } = useTranslation();

  return (
    <>
    {reservation != null ? 
    
        <div className="flex-center">
          <div className="details-container" style={{textAlign: 'center'}}>

            <p style={{ fontWeight: 700}}>{t("FinalStep.NotConfirmedTitle", {
                locationName: reservation.locationName,
              })}</p>
            <p>{t("FinalStep.NotConfirmedThankYou")}</p>
            
          </div>
        </div>
      : null}
    </>
  );
}