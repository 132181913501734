import { useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { useTranslation } from "react-i18next";
import { ReservationDataContext } from "../context/reservationDataContext";
import { DATE_STEP } from "../constants/reservationSteps";
import parse from "html-react-parser";
import moment from "moment";

// 2023-06-18T10:00:00
export default function Dates() {
  const { t, i18n } = useTranslation();

  const { onReservationChange } = useContext(ReservationEntryContext);
  const { nextStep } = useContext(ReservationNavigationContext);
  const { steps, maxDaysInAdvance } = useContext(ReservationDataContext);
  const notes = steps.find((x) => x.id === DATE_STEP).notes;

  const onChange = (e) => {
    const date = moment(e).format("YYYY-MM-DDTHH:mm:ss");
    onReservationChange({ startDate: `${date}Z` });
    nextStep();
  };

  const tileDisabled = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const maxDate = new Date();
    maxDate.setDate(today.getDate() + +maxDaysInAdvance);

    return (
      date?.getTime() < today.getTime() || date?.getTime() > maxDate.getTime()
    );
  };

  return (
    <div className="flex-direction-column">
      <h1 className="large-p">{t("DatesStep.Title")}</h1>
      <div className="page">
        <div className="wrapper">
          <Calendar
            onChange={onChange}
            value={new Date()}
            locale={i18n.language}
            tileDisabled={tileDisabled}
          />
        </div>
      </div>

      {notes.length > 0 ? (
        <div
          style={{
            color: "#314D69",
            marginTop: "1em",
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "1.5em",
          }}
        >
          {notes.map((note) => (
            <p style={{ margin: 0, fontSize: "0.9em" }}>{parse(note)}</p>
          ))}
        </div>
      ) : null}
    </div>
  );
}
